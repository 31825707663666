import "./App.css";
import Home from "./views/Home";
import Navbar from "./components/Navbar";
// import { Error } from "./views/Error";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Services from "./components/Services";
import Calculators from "./components/calculators/Calculators";
import ContactMe from "./components/ContactMe";
// import AboutMe from "./components/AboutMe";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <Router>
        {/* Navbar will always be on the page since 
      it is not inside of a route path*/}
        <Navbar />
        <div id="home"></div>
        <Switch>
          <Route path="/" component={Home} />
          {/* <Route path="*" component={Error} /> */}
        </Switch>
        <div className="content">
          <div id="services"></div>
          <Services />
          <Calculators />
          <div id="calculators"></div>
          <ContactMe />
          <div id="contact-me"></div>
          {/* <AboutMe /> */}
          <div id="about-me"></div>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
