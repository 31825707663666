import { useState, useEffect, useCallback } from "react";

export const useIsMobile = (initialState) => {
  const [isMobile, setisMobile] = useState(initialState);

  const checkMobile = useCallback(() => {
    const screenSize = window.innerWidth;
    if (screenSize <= 768) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkMobile);
    checkMobile();
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, [isMobile, checkMobile]);

  let result = [];
  result.push(isMobile);
  return result;
};
