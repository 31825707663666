import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CityscapeLogoColor from "../assets/img/CityscapeLogoColor.png";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Link as ScrollLink } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: "white",
    typography: {
      fontFamily: `'Open Sans', 'Roboto'`,
    },
    marginTop: "10px",
    textAlign: "center",
  },
  footerIcons: {
    marginRight: 2,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div style={{ background: "white" }}>
      <div style={styles.footer}>
        <div style={styles.textContainer}>
          <h2 style={styles.heading}>
            Need Assistance or a <span style={{ color: "#F50057" }}>FREE </span>
            Home Evaluation?
          </h2>
          <h2 style={styles.heading}>
            Let me add you to the list of my many happy clients!
          </h2>
        </div>
        <div style={styles.textContainer}>
          <h2 style={styles.heading}>What Are You Waiting For?</h2>
          <ScrollLink
            activeClass="active"
            to="contact-me"
            spy={true}
            smooth={true}
            duration={1000}
            offset={0}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Contact Me Today
            </Button>
          </ScrollLink>
        </div>
      </div>
      <div style={styles.footerLogoContainer}>
        <img
          src={CityscapeLogoColor}
          alt="CityScape Real Estate LTD., Brokerage"
          style={styles.footerLogo}
        />
      </div>

      <div style={styles.footerInfoContainer}>
        <h5 style={styles.footerInfo}>
          <CopyrightIcon className={classes.footerIcons} fontSize="small" />
          Cityscape Logo is Copyright Cityscape Real Estate LTD. Brokerage |
          www.cityscapeone.com
        </h5>
        <h5 style={styles.footerInfo}>
          {/* Developed by Danial Qureshi | danialqureshi.m@gmail.com */}
        </h5>
      </div>
    </div>
  );
};

const styles = {
  footer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "4vh",
    paddingRight: 20,
    paddingLeft: 20,
    border: "none",
    background: "white",
  },
  textContainer: {
    marginLeft: 10,
    textAlign: "center",
  },
  heading: {
    color: "black",
    marginBottom: 10,
  },
  footerLogoContainer: {
    postion: "relative",
    height: "9.5vh",
    background: "white",
    border: "none",
    marginBottom: 10,
  },
  footerLogo: {
    position: "absolute",
    height: "9.5vh",
    right: 13,
  },
  footerInfoContainer: {
    color: "black",
    background: "white",
    textAlign: "center",
    borderTop: "1px solid gray",
    padding: 10,
  },
  footerInfo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 2,
  },
};

export default Footer;
