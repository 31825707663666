import React from "react";
import ContactContentCard from "./calculators/ContentCards/ContactContentCard";
import ContactsIcon from "@material-ui/icons/Contacts";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const contactData = {
  CardIcon: ContactsIcon,
  iconColor: "primary",
  heading: "My Contact Information",
  PhoneIcon: PhoneCallbackIcon,
  phoneNumber: "(416)-602-7080",
  EmailIcon: EmailIcon,
  email: "adqureshirealtor@gmail.com",
  LocationIcon: LocationOnIcon,
  officeAddress: "885 Plymouth Dr #2,",
  officeCity: "Mississauga, ON L5V 3E2",
};

const componentData = {
  title: "Contact Me",
  description:
    "Please reach out to me if you need any assistance as well as for a FREE home valuation! You may contact me with the information provided below, as well as by filling out the provided form below.",
};
const ContactMe = () => {
  return <ContactContentCard contactData={contactData} {...componentData} />;
};

export default ContactMe;
