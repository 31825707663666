import React from "react";
import ContentCard from "./ContentCards/ContentCard";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
const calculatorsData = [
  {
    id: 1,
    Icon: MonetizationOnOutlinedIcon,
    iconColor: "primary",
    heading: "Mortgage Calculator",
    descriptionHeading:
      "Use the mortgage calculator for payment calculations. You can also view a corrisponding payment schedule. ",
    buttonLabel: "Use Mortgage Calculator",
    name: "MortgageCalc",
  },
  {
    id: 2,
    Icon: MonetizationOnOutlinedIcon,
    iconColor: "primary",
    heading: "Affordability Calculator",
    descriptionHeading:
      "Check your affordability using the provided calculator.",
    buttonLabel: "Use Affordability Calculator",
    name: "AffordabilityCalc",
  },
];

const componentData = {
  title: "Calculators",
  description:
    "Please use the provided calculators below for your specific needs.",
};
const Calculators = () => {
  return (
    <ContentCard
      isCalculator={true}
      data={calculatorsData}
      {...componentData}
    />
  );
};

export default Calculators;
