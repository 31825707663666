import { useState, useEffect, useCallback } from "react";

export const useIsTablet = (initialState) => {
  const [isTablet, setisTablet] = useState(initialState);

  const checkTablet = useCallback(() => {
    const screenSize = window.innerWidth;
    if (screenSize >= 481 && screenSize <= 768) {
      setisTablet(true);
    } else {
      setisTablet(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkTablet);
    checkTablet();
    return () => {
      window.removeEventListener("resize", checkTablet);
    };
  }, [isTablet, checkTablet]);

  let result = [];
  result.push(isTablet);
  return result;
};
