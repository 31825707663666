import React from "react";
import MortgageCalculator from "mortgage-calculator-react";

const MortgageCalculatorTool = ({ showSchedule }) => {
  return (
    <MortgageCalculator
      price={125000}
      downPayment={10000}
      interestRate={0.065}
      months={120}
      additionalPrincipalPayment={100}
      taxRate={0.01}
      insuranceRate={0.01}
      mortgageInsuranceEnabled={false}
      showPaymentSchedule={showSchedule ? true : false}
    />
  );
};

// const customStyle = {};

export default MortgageCalculatorTool;
