import React, { useEffect, useRef } from "react";

const AffordabilityCalculatorTool = () => {
  const refContainer = useRef(null);

  useEffect(() => {
    var node = document.getElementById("affordability-calc");
    node.style["display"] = "flex";
    refContainer.current.appendChild(node);

    return () => {
      node.style["display"] = "none";
      document.getElementsByTagName("body")[0].appendChild(node);
    };
  }, []);

  return <div ref={refContainer}></div>;
};

export default AffordabilityCalculatorTool;
