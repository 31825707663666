import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link as ScrollLink } from "react-scroll";
import Button from "@material-ui/core/Button";
import { useIsMobile } from "../hooks/useIsMobile";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: 10,
  },
  tab: {
    width: 130,
    minWidth: 80,
    color: (props) => (props.navbarColor === "transparent" ? "black" : "white"),
  },
  menu: {
    "& div": {
      // this is just an example, you can use vw, etc.
      width: "55%",
      background: "rgba(255, 255, 255, .93)",
    },
  },
  menuItem: {
    fontSize: 20,
    padding: "30px 0px 10px 0px",
    justifyContent: "center",
  },
});

const navItems = [
  { id: 1, name: "Services", navIDSelector: "services" },
  { id: 2, name: "Caculators", navIDSelector: "calculators" },
  { id: 3, name: "Contact Me", navIDSelector: "contact-me" },
  // { id: 4, name: "About Me", navIDSelector: "about-me" },
];

const Navbar = () => {
  const [isMobile] = useIsMobile(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [navbarColor, setNavbarColor] = useState("transparent");
  const open = Boolean(anchorEl);
  const classes = useStyles({ navbarColor });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkScroll = () => {
    const distanceFromTop = window.scrollY;
    if (distanceFromTop > 177) {
      setNavbarColor("primary");
    } else {
      setNavbarColor("transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <div className={classes.root}>
      <AppBar color={navbarColor} position="fixed">
        <Toolbar>
          <div className={classes.root}>
            <IconButton
              aria-label="Home"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              component={ScrollLink}
              to="home"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={1000}
              offset={0}
            >
              <Home />
              <Typography variant="h6" className={classes.title}>
                Adnan Qureshi
              </Typography>
            </IconButton>
            {isMobile && (
              <div>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                  className={classes.menu}
                  onBackdropClick={handleClose}
                >
                  {navItems.map((item) => {
                    const { id, name, navIDSelector } = item;
                    return (
                      <ScrollLink
                        key={id}
                        activeClass="active"
                        to={navIDSelector}
                        spy={true}
                        smooth={true}
                        duration={1000}
                        offset={0}
                      >
                        <MenuItem
                          className={classes.menuItem}
                          key={id}
                          onClick={handleClose}
                        >
                          {name}
                        </MenuItem>
                      </ScrollLink>
                    );
                  })}
                </Menu>
              </div>
            )}
          </div>

          {!isMobile ? (
            <div>
              {navItems.map((item) => {
                const { id, name, navIDSelector } = item;
                return (
                  <ScrollLink
                    key={id}
                    activeClass="active"
                    to={navIDSelector}
                    spy={true}
                    smooth={true}
                    duration={1000}
                    offset={0}
                  >
                    <Button styles={{ font: "white" }} className={classes.tab}>
                      {name}
                    </Button>
                  </ScrollLink>
                );
              })}
            </div>
          ) : (
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              onClick={handleMenu}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
