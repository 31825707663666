import React from "react";
import CityscapeLogo from "../assets/img/CityscapeLogo.png";
import agentPicture from "../assets/img/AdnanQureshi.png";
import CityscapeOutline from "../assets/img/CityscapeOutline.png";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link as ScrollLink } from "react-scroll";
import { useIsMobile } from "../hooks/useIsMobile";
import { useIsTablet } from "../hooks/useIsTablet";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: "white",
    typography: {
      fontFamily: `'Open Sans', 'Roboto'`,
    },
    "&:hover": {
      backgroundColor: "black",
    },
    backgroundColor: "#354e5fcc",
    textAlign: "center",
    fontSize: (props) =>
      props.isTablet ? "20px" : props.isMobile ? "16px" : "20px",
    margin: 12,
  },
}));
const Home = () => {
  const [isMobile] = useIsMobile(false);
  const [isTablet] = useIsTablet(false);

  const classes = useStyles({ isMobile, isTablet });

  const styles = {
    heading: {
      position: "absolute",
      width: "fit-content",
      height: "fit-content",
      top: 0,
      right: 0,
      bottom: 180,
      left: 0,
      margin: "auto",
      color: "white",
      textAlign: "center",
      fontSize: isTablet ? "23px" : isMobile ? "18px" : "23px",
      borderRadius: 100,
      padding: 30,
      fontFamily: "Open Sans, Roboto",
    },
  };

  return (
    <>
      <div className="background">
        <h2 style={styles.heading}>
          Lets Invest in Your Future Together <br />
          <ScrollLink
            activeClass="active"
            to="contact-me"
            spy={true}
            smooth={true}
            duration={1000}
            offset={0}
          >
            <Button
              size={isMobile ? "small" : "medium"}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Contact Me Today
            </Button>
          </ScrollLink>
          <br />
          For a FREE Home Evaluation!
        </h2>
        <div className="background-img">
          <div className="background-img-footer">
            <img
              className="cityscape-outline"
              src={CityscapeOutline}
              alt="CityScape Real Estate LTD"
            />
            <img
              className="agent-display-pic"
              src={agentPicture}
              alt="Adnan Qureshi"
            />
            <div className="footer-conatiner-2">
              <div className="header">
                <h1>Adnan Qureshi</h1>
                <h4>
                  Real Estate Agent <br /> (416)-602-7080
                </h4>
              </div>
              <img
                src={CityscapeLogo}
                alt="CityScape Real Estate LTD., Brokerage"
                className="footer-logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
