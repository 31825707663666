import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MortgageCalculatorTool from "../MortgageCalculatorTool";
import AffordabilityCalculatorTool from "../AffordabilityCalculatorTool";
import { Link as ScrollLink } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: 20,
    minHeight: 300,
  },
  gridItem: {
    textAlign: "center",
  },
}));

const ContentCard = ({ isCalculator, title, description, data }) => {
  const classes = useStyles();

  const [showMortgageCalc, setshowMortgageCalc] = useState(false);
  const [showAffordabilityCalc, setshowAffordabilityCalc] = useState(false);
  const [showPaymentSchedule, setshowPaymentSchedule] = useState(false);
  const calcInfo = [];

  const closeOtherCalc = (name) => {
    if (name === "MortgageCalc") {
      setshowAffordabilityCalc(false);
    } else if (name === "AffordabilityCalc") {
      setshowPaymentSchedule(false);
      setshowMortgageCalc(false);
    }
  };
  const handleButtonToggle = (name) => {
    closeOtherCalc(name);
    if (name === "MortgageCalc") {
      setshowMortgageCalc(!showMortgageCalc);
    } else if (name === "AffordabilityCalc") {
      setshowAffordabilityCalc(!showAffordabilityCalc);
    } else if (name === "PaymentSchedule") {
      setshowPaymentSchedule(!showPaymentSchedule);
    }
  };

  const getCalculatorScreen = (name, id) => {
    if (isCalculator) {
      if (name === "MortgageCalc" && showMortgageCalc) {
        return (
          <Paper key={id} elevation={3} className={classes.paper}>
            <MortgageCalculatorTool showSchedule={showPaymentSchedule} />
            <Button
              onClick={() => handleButtonToggle("PaymentSchedule")}
              variant="contained"
              color="secondary"
              style={{ margin: 10 }}
            >
              {!showPaymentSchedule
                ? "Show Payment Schedule"
                : "Hide Payment Schedule"}
            </Button>
            <ScrollLink
              key={id}
              activeClass="active"
              to="calculators"
              spy={true}
              smooth={true}
              duration={1000}
              offset={0}
            >
              <Button
                onClick={() => handleButtonToggle("MortgageCalc")}
                variant="contained"
                color="secondary"
                style={{ margin: 10 }}
              >
                Close Calculator
              </Button>
            </ScrollLink>
          </Paper>
        );
      } else if (name === "AffordabilityCalc" && showAffordabilityCalc) {
        return (
          <Paper id={name} key={id} elevation={3} className={classes.paper}>
            <AffordabilityCalculatorTool />
            <ScrollLink
              key={id}
              activeClass="active"
              to="calculators"
              spy={true}
              smooth={true}
              duration={1000}
              offset={0}
            >
              <Button
                onClick={() => handleButtonToggle("AffordabilityCalc")}
                variant="contained"
                color="secondary"
                style={{ margin: 10 }}
              >
                Close Calculator
              </Button>
            </ScrollLink>
          </Paper>
        );
      }
    }
  };

  return (
    <>
      <div className="content-container">
        <Grid justify="center" className={classes.grid} container spacing={2}>
          <Grid item className={classes.gridItem} xs={12}>
            <h1 className="content-title"> {title} </h1>
            <h4 className="content-subtitle">{description}</h4>
          </Grid>
          {data.map((data) => {
            const {
              id,
              Icon,
              iconColor,
              heading,
              descriptionHeading,
              description,
              buttonLabel,
              name,
            } = data;
            if (isCalculator) {
              calcInfo.push({ id, name });
            }
            return (
              <Grid
                key={id}
                item
                className={classes.gridItem}
                xs={12}
                md={isCalculator ? 6 : 4}
              >
                <Paper elevation={3} className={classes.paper}>
                  <Icon fontSize="large" color={iconColor} />
                  <h4 className="card-heading">{heading}</h4>
                  <p>{descriptionHeading}</p>
                  <br />
                  <p>{description}</p>
                  {buttonLabel && (
                    <ScrollLink
                      key={id}
                      activeClass="active"
                      to={
                        showMortgageCalc || showAffordabilityCalc
                          ? "calculators"
                          : name
                      }
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={0}
                    >
                      <Button
                        onClick={() => handleButtonToggle(name)}
                        variant="contained"
                        color="primary"
                      >
                        {(name === "MortgageCalc" && !showMortgageCalc) ||
                        (name === "AffordabilityCalc" && !showAffordabilityCalc)
                          ? buttonLabel
                          : "Close Calculator"}
                      </Button>
                    </ScrollLink>
                  )}
                </Paper>
              </Grid>
            );
          })}

          {calcInfo.map((info) => {
            const { id, name } = info;
            return (
              <div key={id}>
                <div id={name}></div>
                {getCalculatorScreen(name, id)}
              </div>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default ContentCard;
