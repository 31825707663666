import React from "react";
import ContentCard from "./calculators/ContentCards/ContentCard";
import Home from "@material-ui/icons/Home";
import LocationCity from "@material-ui/icons/LocationCity";
import Build from "@material-ui/icons/Build";

const servicesData = [
  {
    id: 1,
    Icon: Home,
    iconColor: "primary",
    heading: "Residential",
    descriptionHeading:
      "Are you looking to buy or sell a Residential Property?",
    description:
      " I provide Residential Real Estate services including buying or selling a  residential home.",
  },
  {
    id: 2,
    Icon: LocationCity,
    iconColor: "secondary",
    heading: "Commercial",
    descriptionHeading: "Are you looking for Commercial Real Estate services?",
    description:
      " I provide Commercial Real Estate services including buying or selling a Commercial property.",
  },
  {
    id: 3,
    Icon: Build,
    iconColor: "inherit",
    heading: "Pre-Construction",
    descriptionHeading:
      "Are you looking for Pre-Construction Real Estate services?",
    description:
      "I provide Pre-Construction Real Estate services by working directly with the builders.",
  },
];

const componentData = {
  title: "Services",
  description:
    "I want to provide you the best real estate assistance tailored for your specific needs. I offer services pertaining to buying/selling Commercial and Residential properties as well as Pre-Contructions.",
};
const Services = () => {
  return <ContentCard {...componentData} data={servicesData} />;
};

export default Services;
